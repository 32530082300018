import React from "react";

function Footer() {
  return (
    <div className="Footer">
      <h3>Ctrl-Alt-Defeat</h3>
      <div>
        <p>Copyright © 2024 Ctrl-Alt-Defeat. All Rights Reserved.</p>
      </div>
    </div>
  );
}

export default Footer;
